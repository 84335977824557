.footerBar {
    width: 100%;
    .navList {
          display: flex;
          list-style: none;
          justify-content: space-between;
          padding: 0;
          @include fontDetails(1.1rem, normalText, 500, $text-main);
          overflow: hidden;
          animation: show 300ms cubic-bezier(0.01, -0.16, 1, 0.37) 2500ms
                forwards;
          transform-origin: left;
          .logo {
                @include fontDetails(1.125rem, normalText, 600, $text-main);
                padding: 9px;
          }
          .linked {
                @include linkedEffect();
          }
    }
};


@media (min-width:567px) and (max-width:768px){
      .footerBar{
            .navList{
                  flex-direction: column;
                  .logo{
                        padding-bottom:1.5rem;
                  }

            }
      }
}

@media (max-width:567px){
      .footerBar{
            .navList{
                  flex-direction: column;
                  .logo{
                        @include fontDetails(0.9rem, normalText, 600, $text-main);
                  }
                  .linked{
                        font-size: 0.9rem;
                  }
            }
      }
}