.myWork {
      max-height: fit-content;
      @include fontDetails(4.5rem, normalText, 500, $text-main);
      // .workText {
      //       opacity: 1;
      //       position: relative;
      //       width: fit-content;
      //       text-align: center;
      //       align-self: center;
      //       margin-left: 3rem;
      //       font-variant: small-caps;
      //       @include fontDetails(10rem, normalText, 600, $text-sec);
      //       cursor: pointer;
      //       &:hover .lineBox {
      //             transition: opacity 100ms ease;
      //             animation: lineDraw 400ms cubic-bezier(0.01, -0.16, 1, 0.37)
      //                   forwards;
      //             opacity: 1;
      //       }
      //       &:hover {
      //             transition: color 400ms ease-in-out;
      //             color: $workTextHover;
      //       }
      //       .lineBox {
      //             overflow: hidden;
      //             height: inherit;
      //             opacity: 0;
      //             .lineUnderText {
      //                   border: solid 3px $modal-hover;
      //                   border-color: $workTextHover transparent transparent
      //                         transparent;
      //             }
      //       }
}
.workList {
      width: 100%;
      max-height: 560px;
      overflow: hidden;
      margin-bottom: 2rem;
      &:hover #leftBtn,
      &:hover #rightBtn {
            transition: opacity 300ms ease;
            opacity: 1;
      }
      #leftBtn {
            @include sliderBtns();
            position: absolute;
            height: 560px;
            left: 2rem;
            z-index: 1040;
      }
      #rightBtn {
            @include sliderBtns();
            position: absolute;
            height: 560px;
            right: 2rem;
            z-index: 1040;
      }
      .workInsideBox {
            height: 560px;
            width: 100%;
            display: flex;
            // edit the number based on how many elements under wokrlist
            @for $i from 1 through 8 {
                  #p#{$i} {
                        position: relative;
                        max-height: inherit;
                        transition: all 600ms ease-out;
                        width: 33.3333%;
                        img {
                              width: 27.2rem;
                              height: 100%;
                              object-fit: cover;
                              pointer-events: none;
                              // &:hover {
                              //       transition: filter 250ms ease;
                              //       filter: brightness(40%);
                              // }
                              // &:hover + .darkBackBtn{
                              //  animation: show 250ms ease 0ms forwards;
                              // }
                        }
                        .darkBack {
                              position: absolute;
                              width: 100%;
                              height: 100%;
                              display: inline-block;
                              z-index: 1030;
                              background: transparent;
                              box-sizing: border-box;
                              &:hover {
                                    transition: backdrop-filter 250ms ease;
                                    backdrop-filter: brightness(40%);
                              }

                              &:hover .darkBackInfo {
                                    animation: show 250ms ease 0ms forwards;
                              }
                              .darkBackInfo {
                                    opacity: 0;
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-direction: column;
                                    .someInfo {
                                          @include fontDetails(
                                                1.5rem,
                                                normalText,
                                                400,
                                                $bg-main
                                          );
                                    }
                                    .darkBtn {
                                          @include darkBackBtn();
                                          z-index: 1040;
                                    }
                              }
                        }
                  }
            }
      }
}

@media (min-width: 992px) and (max-width: 1200px) {
      .myWork {
            .workText {
                  @include fontDetails(4rem, normalText, 500, $modal-hover);
                  margin-left: 10rem;
            }
            .workList {
                  #leftBtn,#rightBtn{
                        opacity: 1;
                        height: 500px;
                  }
                  #leftBtn {
                        left: 2rem;
                  }
                  #rightBtn {
                        right: 2rem;
                  }
                  .workInsideBox {
                        max-height: 500px;
                        @for $i from 1 through 8 {
                              #p#{$i} {
                                    width: 33.333333%;
                                    img{
                                          width: 25rem;
                                    }
                              }
                        }
                  }
            }
      }
}

@media (min-width: 768px) and (max-width: 992px) {
      .myWork {
            .workText {
                  @include fontDetails(4rem, normalText, 500, $modal-hover);
                  margin-left: 10rem;
            }
            .workList {
                  #leftBtn,#rightBtn{
                        opacity: 1;
                        height: 500px;
                  }
                  #leftBtn {
                        left: 2rem;
                  }
                  #rightBtn {
                        right: 2rem;
                  }
                  .workInsideBox {
                        max-height: 500px;
                        @for $i from 1 through 8 {
                              #p#{$i} {
                                    width: 33.333333%;
                                    img{
                                          width: 20rem;
                                    }
                              }
                        }
                  }
            }
      }
}

@media (min-width: 567px) and (max-width: 768px) {
      .myWork {
            .workText {
                  @include fontDetails(4rem, normalText, 500, $modal-hover);
                  @media (max-width: 650px) {
                        margin-left: 5.5rem;
                  }
                  margin-left: 10rem;
            }
            .workList {
                  #leftBtn,#rightBtn{
                        opacity: 1;
                        height: 500px;
                  }
                  #leftBtn {
                        left: 1.2rem;
                  }
                  #rightBtn {
                        right: 1.2rem;
                  }
                  .workInsideBox {
                        max-height: 500px;
                        @for $i from 1 through 8 {
                              #p#{$i} {
                                    width: 33.333333%;
                                    img{
                                          width: 15rem;
                                    }
                              }
                        }
                  }
            }
      }
}

@media (max-width: 567px) {
      .myWork {
            .workText {
                  @include fontDetails(3rem, normalText, 500, $modal-hover);
                  margin-left: 2rem;
            }
            .workList {
                  #leftBtn,#rightBtn{
                        opacity: 1;
                        height: 500px;
                  }
                  #leftBtn {
                        left: .6rem;
                  }
                  #rightBtn {
                        right: .6rem;
                  }
                  .workInsideBox {
                        max-height: 500px;
                        @for $i from 1 through 8 {
                              #p#{$i} {
                                    width: 50%;
                                    img{
                                          width: 17rem;
                                    }
                              }
                        }
                  }
            }
      }
}
