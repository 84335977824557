.header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;
      grid-column-gap: 4rem;
      grid-template-areas:
            "location img"
            "hello img"
            "name img"
            "about img"
            "linked img";
      .location {
            grid-area: location;
            @include fontDetails(16px, normalText, 600, $text-main);
            align-self: end;
            overflow: hidden;
            animation: slideUp $header-animation-duration
                  cubic-bezier(0, 0, 0.18, 1.01) 2900ms forwards;
            transform-origin: left;
            height: fit-content;
      }
      .hello {
            grid-area: hello;
            @include fontDetails(4.5rem, normalText, 500, $text-main);
            align-self: center;
            overflow: hidden;
            animation: slideUp $header-animation-duration
                  cubic-bezier(0, 0, 0.18, 1.01) 3200ms forwards;
            transform-origin: left;
      }
      .name {
            grid-area: name;
            @include fontDetails(3.75rem, normalText, 500, $text-main);
            overflow: hidden;
            animation: slideUp $header-animation-duration
                  cubic-bezier(0, 0, 0.18, 1.01) 3300ms forwards;
            transform-origin: left;
      }
      .about {
            grid-area: about;
            @include fontDetails(1.3rem, normalText, 300, $text-main);

            line-height: 1.5;
            overflow: hidden;
            animation: slideUp $header-animation-duration
                  cubic-bezier(0, 0, 0.18, 1.01) 3500ms forwards;
            transform-origin: left;
      }
      .linked {
            grid-area: linked;
            @include fontDetails(1.1rem, normalText, 600, $text-main);
            height: fit-content;
            width: fit-content;
            @include linkedEffect();
            overflow: hidden;
            animation: slideUp $header-animation-duration
                  cubic-bezier(0, 0, 0.18, 1.01) 3700ms forwards;
            transform-origin: left;
      }
      .profileImage {
            grid-area: img;
            width: 560px;
            max-height: 560px;
            animation: show $header-animation-duration
                  cubic-bezier(0, 0, 0.18, 1.01) 2900ms forwards;
            transform-origin: left;
            img {
                  margin-top: 5rem;
                  object-fit: cover;
                  object-position: top;
                  width: 100%;
                  height: 100%;
                  border-radius: 100%;
            }
      }
}

@media (min-width: 992px) and (max-width: 1200px) {
      .header {
            display: grid;
            min-height: 550px;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: .3fr .3fr .3fr .7fr .3fr;
            grid-column-gap: 1.5rem;
            grid-template-areas:
                  "location img"
                  "hello img"
                  "name img"
                  "about img"
                  "linked img";
            
            .hello {
                  @include fontDetails(3.5rem, normalText, 500, $text-main);
            }
            .name {
                  overflow: visible;
                  @include fontDetails(3.5rem, normalText, 500, $text-main);
            }
            .about {
                  align-self: center;
            }

      }
}

@media (min-width: 768px) and (max-width: 992px) {
      .header {
            display: grid;
            min-height: 550px;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: .3fr .3fr .3fr .7fr .3fr;
            grid-column-gap: 1.5rem;
            grid-template-areas:
                  "location img"
                  "hello img"
                  "name img"
                  "about img"
                  "linked img";
            .profileImage {
                  img {
                        margin-top: 5rem;
                        max-width: 100%;
                        max-height: 100%;
                  }
                  justify-self: center;
                  max-width: 400px;
                  max-height: 400px;
            }
            .hello {
                  @include fontDetails(3rem, normalText, 500, $text-main);
            }
            .name {
                  overflow: visible;
                  @include fontDetails(3rem, normalText, 500, $text-main);
            }
            .about {
                  align-self: center;
                  overflow: visible;
                  @include fontDetails(1.3rem, normalText, 300, $text-main);
            }
            .linked{
                  align-self: end;
            }
      }
}

@media (min-width: 567px) and (max-width: 768px) {
      .header {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 0.2fr 0.15fr 0.15fr 0.2fr 0.2fr 0.3fr;
            grid-template-areas:
                  "location"
                  "hello"
                  "name"
                  "about"
                  "linked"
                  "img";
            .profileImage {
                  img {
                        margin-top: 0rem;
                  }
                  justify-self: center;
            }
            .hello {
                  @include fontDetails(2.5rem, normalText, 500, $text-main);
            }
            .name {
                  @include fontDetails(2.5rem, normalText, 500, $text-main);
            }
            .about {
                  overflow: visible;
                  @include fontDetails(1.1rem, normalText, 300, $text-main);
            }
      }
}

@media (max-width: 567px) {
      .header {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;
            grid-template-areas:
                  "location"
                  "hello"
                  "name"
                  "about"
                  "linked"
                  "img";
            .profileImage {
                  img {
                        margin-top: 0rem;
                  }
                  max-width: 340px;
                  max-height: 340px;
                  justify-self: center;
            }
            .hello {
                  @include fontDetails(2.2rem, normalText, 500, $text-main);
            }
            .name {
                  @include fontDetails(2rem, normalText, 500, $text-main);
            }
            .about {
                  overflow: visible;
                  @include fontDetails(1rem, normalText, 300, $text-main);
            }
      }
}
