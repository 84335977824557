.preloaderText {
      position: fixed;
      left: 50%;
      top: 55%;
      transform: translate(-50%, -50%);
      @include fontDetails(5rem, signature, 500, $text-main);
      width: 20rem;
      overflow: hidden;
      pointer-events: none;
      .firstNameText {
            overflow: hidden;
            perspective: 1000px;
            transform-style: preserve-3d;
            .animatedText {
                  transform-origin: left;
                  animation: slideUp 500ms cubic-bezier(0, 0, 0.18, 1.01);
                  animation-fill-mode: forwards;
                  transform-style: preserve-3d;
            }
      }
      .lastNameText {
            @extend .firstNameText;
            text-indent: 160px;
            .animatedText2 {
                  @extend .animatedText;
                  animation-delay: 300ms;
            }
      }
      .lineBox {
            overflow: hidden;
            height: inherit;
            padding-top: 10px;
            animation: lineDraw 1000ms 500ms cubic-bezier(0.01, -0.16, 1, 0.37)
            forwards;
            .lineUnderText {
                  width: 700px;
                  height: 100px;
                  border: solid 5px $text-main;
                  border-color: $text-main transparent transparent transparent;
                  border-radius: 50%/100px 100px 0 0;
                  transform: rotateZ(5deg);
                  margin-left: 50px;
            }
      }
}

@keyframes lineDraw {
      0% {
            width: 0%;
      }

      100% {
            width: 100%;
      }
}


