.contactModal {
      .triangleShape {
            width: 100%;
            height: 100%;
            position: absolute;
            clip-path: polygon(100% 40%, 0% 100%, 100% 100%);
            background: $bg-modal-sec;
      }
      opacity: 0;
      pointer-events: none;
      background-color: $bg-modal;
      color: $text-sec;
      width: 95%;
      height: 89%;
      position: fixed;
      z-index: 1000;
      margin: 20px;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      .closeBtn {
            font-size: xx-large;
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
            &:hover {
                  color: $modal-hover;
            }
      }
      .mainInfo {
            text-align: center;
            z-index: 1000;
            width: fit-content;
            margin: 0 0 10rem 0;
            .contactText {
                  @include fontDetails(24px, normalText, 500, inherit);
                  text-transform: capitalize;
                  padding-bottom: 15px;
            }
            .popUp {
                  position: absolute;
                  @include fontDetails(16px, normalText, 400, inherit);
                  background-color: $selectedBack;
                  border-radius: 10px;
                  color: $selectedColor;
                  padding-top: 1rem;
                  width: fit-content;
                  height: 2rem;
                  opacity: 0;
            }
            .mail {
                  @include fontDetails(4.5em, normalText, 500, inherit);
                  cursor: pointer;
                  &:hover + .lineBox {
                        transition: opacity 100ms ease;
                        animation: lineDraw 400ms
                              cubic-bezier(0.01, -0.16, 1, 0.37) forwards;
                        opacity: 1;
                  }
                  &:hover {
                        transition: color 400ms ease-in-out;
                        color: $modal-hover;
                  }
            }
            .lineBox {
                  overflow: hidden;
                  height: inherit;
                  opacity: 0;
                  .lineUnderText {
                        border: solid 3px $modal-hover;
                        border-color: $modal-hover transparent transparent
                              transparent;
                  }
            }
      }
      .socialInfo {
            width: 100%;
            z-index: 1010;
            @include fontDetails(1.125em, normalText, 500, inherit);
            text-align: center;
            .socialText {
                  letter-spacing: 1.5px;
                  font-variant: small-caps;
            }
            .iconsList {
                  display: flex;
                  justify-content: center;
                  list-style: none;
                  padding: 0;
                  li {
                        a {
                              transition: color 400ms ease-in-out;
                              color: $text-main;
                              &:link {
                                    color: $text-main;
                              }
                              &:visited {
                                    color: inherit;
                              }
                              &:hover {
                                    color: $modal-hover;
                              }
                        }
                        margin: 0.5rem;
                  }
            }
      }
}

.heightChanger {
      animation: heighter 300ms ease 100ms forwards;
      transition: opacity 100ms 200ms;
      opacity: 1;
      pointer-events: all;
}

.heightReturner {
      animation: zeroHeight 300ms ease 100ms forwards;
      transition: opacity 100ms 200ms;
      opacity: 0;
      pointer-events: none;
}

@keyframes heighter {
      0% {
            transform: scaleY(0);
      }
      100% {
            transform: scaleY(1);
      }
}

@keyframes zeroHeight {
      0% {
            transform: scaleY(1);
      }
      100% {
            transform: scaleY(0);
      }
}

@media (max-width: 1200px) {
      .contactModal {
            max-width: 93%;
            .mainInfo {
                  margin: 0 0 12.5rem 0;
                  .contactText {
                        @include fontDetails(24px, normalText, 500, inherit);
                  }
                  .mail {
                        @include fontDetails(4rem, normalText, 500, inherit);
                  }
            }
      }
}

@media (max-width: 992px) {
      .contactModal {
            max-width: 93%;
            .mainInfo {
                  margin: 0 0 12.5rem 0;
                  .contactText {
                        @include fontDetails(22px, normalText, 500, inherit);
                  }
                  .mail {
                        @include fontDetails(3rem, normalText, 500, inherit);
                  }
            }
      }
}

@media (max-width: 768px) {
      .contactModal {
            max-width: 88%;
            .mainInfo {
                  margin: 0 0 17.5rem 0;
                  .contactText {
                        @include fontDetails(20px, normalText, 500, inherit);
                  }
                  .mail {
                        @include fontDetails(2rem, normalText, 500, inherit);
                  }
            }
      }
}

@media (max-width: 567px) {
      .contactModal {
            max-width: 88%;
            .mainInfo {
                  margin: 0 0 17.5rem 0;
                  .contactText {
                        @include fontDetails(16px, normalText, 500, inherit);
                  }
                  .mail {
                        @include fontDetails(1.5rem, normalText, 500, inherit);
                  }
            }
      }
}
