$bg-main: #f0e6e6;
$text-main: #131520;
$text-sec: #4b3b32;

$selectedColor: #decac8;
$selectedBack: #4b3b32;

$bg-modal: #fff;
$bg-modal-sec: #fafafa;
$modal-hover: #1338be;

$workTextHover:#9e9a91;


$header-animation-duration: 1200ms;
