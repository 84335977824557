.main {
      padding: 34vh 0 20vh 0;
      max-width: 560px;
      @include fontDetails(1.125rem, normalText, 400, $text-main);
      line-height: 1.5;
      .aboutme {
            font-weight: 600;
            padding-bottom: 0.5em;
            overflow: hidden;
            animation: slideUp $header-animation-duration
                  cubic-bezier(0, 0, 0.18, 1.01) 3800ms forwards;
      }
      .about1 {
            padding-bottom: 0.5em;
            overflow: hidden;
            animation: slideUp $header-animation-duration
                  cubic-bezier(0, 0, 0.18, 1.01) 3900ms forwards;
      }
      .about2 {
            padding-bottom: 1.5em;
            overflow: hidden;
            animation: slideUp $header-animation-duration
                  cubic-bezier(0, 0, 0.18, 1.01) 4000ms forwards;
            .skills {
                  display: flex;
                  font-weight: 500;
            }
      }
      .sig {
            overflow: hidden;
            animation: slideUp $header-animation-duration
                  cubic-bezier(0, 0, 0.18, 1.01) 4100ms forwards;
            @include fontDetails(3rem, signature, 600, $text-main);
      }
}

@include slideUpAnimation();

@media (min-width: 768px) and (max-width: 992px) {
      .main {
            padding: 17.5vh 0 15vh 0;
      }
  }
  

@media (min-width: 567px) and (max-width: 768px) {
    .main {
          @include fontDetails(1rem, normalText, 400, $text-main);
          padding: 10vh 0 10vh 0;
    }
}


@media (max-width: 567px) {
    .main {
          @include fontDetails(1rem, normalText, 400, $text-main);
          padding: 10vh 0 10vh 0;
      }
}