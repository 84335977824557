//variables
@import "./abstracts/variables";
//maps
@import "./abstracts/maps";
//mixins
@import "./abstracts/mixins";
//functions
// @import './abstracts/functions';
//icons
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";
//components
@import "./abstracts/preLoader";
@import './abstracts/navBar';
@import './abstracts/header';
@import './abstracts/main';
@import './abstracts/contactModal';
@import './abstracts/myWork';
@import './abstracts/footer';



body{
    background:$bg-main ;
    ::selection{
        background-color: $selectedBack;
        color: $selectedColor;
    }
    #container{
        margin: 0 1.5rem 0 1.5rem;
    }
}

@media (max-width: 768px) {
    body{
          #container{
                margin: 0 0.75rem 0 .75rem;
          }
    }}


@media (max-width: 567px) {
    body{
          #container{
                margin: 0 0 0 0;
          }
    }}


