.navBar {
      width: 100%;
      .navList {
            display: flex;
            list-style: none;
            justify-content: space-between;
            padding: 0;
            @include fontDetails(1.1rem, normalText, 500, $text-main);
            overflow: hidden;
            animation: show 300ms cubic-bezier(0.01, -0.16, 1, 0.37) 2500ms
                  forwards;
            transform-origin: left;
            .logo {
                  @include fontDetails(1.125rem, normalText, 600, $text-main);
                  padding: 9px;
            }
            .linked {
                  @include linkedEffect();
            }
      }
}

@include opacityAnimationOn();

@media (max-width:768px){
      .navBar{
            .navList{
                  .linked{
                        display: none;
                  }
            }
      }
}