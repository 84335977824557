@mixin fontDetails($size, $family, $weight, $color) {
      font-size: $size;
      font-family: map-get($textFonts, $family);
      font-weight: $weight;
      color: $color;
}

@mixin slideUpAnimation() {
      @keyframes slideUp {
            0% {
                  opacity: 0;
                  transform: translate3d(0px, 50px, 0px) rotateZ(10deg);
            }

            100% {
                  opacity: 1;
                  transform: translate3d(0px, 0px, 0px) rotateZ(0deg);
            }
      }
}
@mixin opacityAnimationOn() {
      @keyframes show {
            0% {
                  opacity: 0;
            }

            100% {
                  opacity: 1;
            }
      }
}

@mixin opacityAnimationOff() {
      @keyframes hide {
            0% {
                  opacity: 1;
            }

            100% {
                  opacity: 0;
            }
      }
}

@mixin linkedEffect {
      padding: 9px;
      cursor: pointer;
      .arrow {
            display: inline-block;
      }
}

@mixin sliderBtns {
      // display: inline-block;
      background: rgba(0, 0, 0, 0.5);
      border: none;
      @include fontDetails(3rem, inherit, 400, white);
      z-index: 1030;
      opacity: 0;
}

@mixin darkBackBtn {
      align-items: center;
      appearance: none;
      background-color: #fcfcfd;
      border-radius: 4px;
      border-width: 0;
      box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
            rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
      box-sizing: border-box;
      color: #36395a;
      cursor: pointer;
      display: inline-flex;
      font-family: map-get($textFonts, normalText);
      height: 48px;
      justify-content: center;
      line-height: 1;
      list-style: none;
      overflow: hidden;
      padding-left: 16px;
      padding-right: 16px;
      position: relative;
      text-align: left;
      text-decoration: none;
      transition: box-shadow 0.15s, transform 0.15s;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      white-space: nowrap;
      will-change: box-shadow, transform;
      font-size: 18px;
      &:focus {
            box-shadow: #d6d6e7 0 0 0 1.5px inset,
                  rgba(45, 35, 66, 0.4) 0 2px 4px,
                  rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
      }
      &:hover {
            box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
                  rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
            transform: translateY(-2px);
      }
      &:active {
            box-shadow: #d6d6e7 0 3px 7px inset;
            transform: translateY(2px);
      }
}
